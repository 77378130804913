import { ApiService } from './api.service';
import { TxInfo } from './models/txInfo';
import { Utils } from './utils';
declare global {
    interface Window {
        ApplePaySession: any;
        parentDomain: string;
    }
}


export default class CgApplePay {
    protected ApplePaySession = window.ApplePaySession;
    private currentSession: any;
    private utils: Utils = null;
    private postMessagePayload: any = {
        dataType: 'applepay',
        action: 'Alive'
    }
    constructor(private debugState: boolean = false) {
        this.utils = new Utils(this.debugState);
        this.utils.logit("apple pay v1.1.1");
                window.addEventListener("message",
            (e) => {
                const action = e.data.action;
                console.log('event from parent', e);
                if (e.data.dataType !== 'applepay') { return; }
                if (action == 'isAlive') {
                    this.returnToIframe(e, this.postMessagePayload)
                }
                if (action == 'startDeal') {
                    const txInfo:TxInfo = e.data.txInfo;
                    const domainName = window.parentDomain ? window.parentDomain : txInfo.merchantSiteDomain;
                    txInfo.merchantSiteDomain = domainName;

                    console.log('start deal from parent', txInfo)
                    this.init(txInfo, (token: any) => {
                        this.postMessagePayload.action = 'submitDeal'
                        this.postMessagePayload.token = token;
                        this.returnToIframe(e, this.postMessagePayload)
                    });
                }
                if (action == 'paymentSucceeded') {
                    this.sucStatus();
                }
                if (action == 'paymentFailure') {
                    this.failStatus()
                }
            }, false);
    }
    public returnToIframe = (e: any, appleData: any) => e.source.postMessage(appleData, e.origin);

    
    public sucStatus(): string {
        return this.currentSession.completePayment(this.ApplePaySession.STATUS_SUCCESS);
    }
    public failStatus(): string {
        return this.currentSession.completePayment(this.ApplePaySession.STATUS_FAILURE);
    }


    public async init(txInfo: any, cb?: Function) {
        if (!this.ApplePaySession) {
            console.error("this browser does not support apple pay");
        } else {
            let canMakePayments = this.ApplePaySession.canMakePayments();
            if (canMakePayments) {
                this.startPayEvent(cb, txInfo);
            } else {
                console.error("fail to generate apple pay process");
            }
        }
    }

    public async performValidation(txInfo:TxInfo): Promise<any> {
        try {
            const apiService = new ApiService();
            var raw = JSON.stringify({
                "action": "performValidation",
                "displayName": txInfo.merchantDisplayName,
                "mid": txInfo.massEnrollmentMerchantId,
                "domainName": txInfo.merchantSiteDomain
            });
            const getMerchantVerification = await apiService.post(txInfo.serviceURL, raw);
            const token = await getMerchantVerification.json()
            return token;
        } catch (e) {
            this.utils.logit(e);
            return "fail to call verify merchant";
        }
    }
    public startPayEvent(cb: Function, txInfo: TxInfo) {
        try {
            let runningAmount: any = txInfo.amount;
            let runningPP: any = 0;
            let runningTotal = function () {
                let tempTotal: any = parseFloat(runningAmount) + parseFloat(runningPP);
                return parseFloat(tempTotal).toFixed(2);
            };


            let subTotalDescr = txInfo.merchantDisplayName;

            let paymentRequest = {
                currencyCode: txInfo.currency,
                countryCode: "IL",
                requiredBillingContactFields: ["email", "name", "phone"],
                lineItems: [{ label: subTotalDescr, amount: runningAmount }],
                total: {
                    label: txInfo.merchantDisplayName,
                    amount: runningTotal()
                },
                supportedNetworks: ["amex", "masterCard", "visa"],
                merchantCapabilities: ["supports3DS"]
            };

             this.currentSession = new this.ApplePaySession(1, paymentRequest);

            // Merchant Validation
            this.currentSession.onvalidatemerchant = async (event: any): Promise<any> => {
                try {
                    this.utils.logit('start verify merchant');
                    let token = await this.performValidation(txInfo);
                    this.currentSession.completeMerchantValidation(token);
                } catch (e) {
                    this.utils.logit(e);
                    return "fail to verify merchant";
                }
            };




            this.currentSession.onpaymentmethodselected = (event: any) => {
                this.utils.logit("starting session.onpaymentmethodselected");
                this.utils.logit(event);

                let newTotal = {
                    type: "final",
                    label: txInfo.merchantDisplayName,
                    amount: runningTotal()
                };
                let newLineItems = [
                    { type: "final", label: subTotalDescr, amount: runningAmount }
                ];
                this.currentSession.completePaymentMethodSelection(newTotal, newLineItems);
            };
            /**
             @param status - reffer to the apple pay session status
             @param validToken - reffer to the transaction details
             */
             this.currentSession.onpaymentauthorized = async (event: any) => {
                this.utils.logit("starting session.onpaymentauthorized");
                this.utils.logit('NB: This is the first stage when you get the *full shipping address* of the customer, in the event.payment.shippingContact object');
                this.utils.logit(event);
                this.utils.logit(event.payment.shippingContact);
                let validToken = event.payment.token;
                try {
                    // validToken = await sendPaymentToken(event.payment.token);
                    cb(validToken)
                } catch (error) {
                    console.error(error)
                    cb(error)
                }
            };


            this.currentSession.oncancel = (event: any) => {
                this.utils.logit("starting session.cancel");
                this.utils.logit(event);
            };

            this.currentSession.begin();

            return this.currentSession;
        } catch (error) {
            console.warn(error);
        }
    }

}

module.exports = CgApplePay;
