// transpile old browser fetch API
import "whatwg-fetch";
export class ApiService {
  public async get(api_url: string): Promise<any> {
    let res = await window.fetch(`${api_url}`, {
      method: "GET",
      headers: {
        Accept: "application/json"
      }
    });
    return res;
  }
  public async post(api_url: string, body?: any): Promise<any> {
    let res = await window.fetch(`${api_url}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body
    });
    return res;
  }
}
