export class Utils {
    constructor(private debugState: boolean) {}
  
    public logit(data: any) {
      if (this.debugState) {
        if (typeof data === "object" && data !== null) {
          data = JSON.stringify(data);
        }
        console.warn(`%c ${data}`, "color: orange");
      }
    }
  }
  